import React from "react";
import DefaultHero from '../../components/DefaultHero';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import QueryString from "../../helper/QueryString";
import { graphql } from "gatsby";
import { Brand } from "../../components/PriceCalculator/elements/Constants";

export default ({ data, pageContext }) => {
    const { city } = pageContext;
    const contextData = {
        title: 'Preisrechner',
        image: '/img/hero/' + (city == null ? data.site.siteMetadata.image : city.slug + '/' + city.image),
        image768: '/img/hero/empty.gif',
        image1024: '/img/hero/' + (city == null ? data.site.siteMetadata.image1024 : city.slug + '/' + city.image1024),
        image1216: '/img/hero/' + (city == null ? data.site.siteMetadata.image1216 : city.slug + '/' + city.image1216),
        image1408: '/img/hero/' + (city == null ? data.site.siteMetadata.image1408 : city.slug + '/' + city.image1408),
        showPriceCalculator: true,
        product: Brand,
        defaultCssClass: 'price-hero',
        showBottom: true,
        city: city,
        noIndex: true
    };
    if (typeof city !== 'undefined') {
        let query = new QueryString();
        query.setValue('region', city.key);
    }
  return (
    <div>
            <Header page={data.site.siteMetadata.priceMetadata} siteMetadata={data.site.siteMetadata} contextData={contextData} />
            <DefaultHero context={contextData}/>
            <Footer small={true}/>
    </div>
  );
};

export const query = graphql`
  query BrandmeldeanlagePriceCalcQuery {
    site {
        siteMetadata {
            title
            keywords
            description
            headerImage
            ogTitle
            ogDescription
            image
            image768
            image1024
            image1216
            image1408
            priceMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
            }
        }

    }
  }
`;
